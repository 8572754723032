.section-our-expertise {
  position: relative;
  min-height: 1135px;
  transition: all 0.5s;

  .container {
    max-width: 1280px;
    padding: 0px 80px;
    margin: auto;

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-button {
        max-width: 335px;
        position: relative;

        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          text-transform: uppercase;
          color: #E75E24;
          padding-bottom: 10px;
        }

        h1 {
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 700;
          font-size: 45px;
          line-height: 52px;
          color: #212E5E;
          padding-bottom: 15px;
        }

        p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: #8D9BAA;
        }

        a {
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          align-items: center;
          color: #ffffff;
          width: 172px;
          height: 56px;
          border-radius: 30px;
          background: linear-gradient(270deg, #e75e24 0%, #f9893d 100%);
          display: inline-flex;
          justify-content: center;
          align-items: center;
          transition: all 0.5¡8s;
          cursor: pointer;
          margin-top: 35px;

          &:hover {
            background: linear-gradient(270deg, #F9893D 0%, #f9893d 100%);
          }
        }

        .elipse-icon {
          position: absolute;
          left: 0;
          bottom: -40px;

          &::after {
            content: "";
            height: 506px;
            width: 137px;
            position: absolute;
            left: 9px;
            z-index: 2;
            border: solid 1px #DAE2ED;
            border-right: 0;
            border-top: 0;
          }

          &::before {
            border-radius: 50%;
            bottom: -512px;
            content: url('https://depuemechdev.wpengine.com/wp-content/uploads/2022/05/orange-circle.png');
            height: 20px;
            left: 139px;
            position: absolute;
            width: 20px;
            padding: 4px;
            z-index: 2;
          }
        }
      }

      .content-card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 800px;
        position: relative;
        top: -90px;
        margin-bottom: 42px;
        position: relative;
        z-index: 2;

        @media (max-width: 768px) {
          top: -29px;
        }

        .card {
          max-width: 360px;
          min-height: 115px;
          background: #FFFFFF;
          box-shadow: 0px 5px 20px rgba(45, 83, 151, 0.15);
          padding: 32.5px 33px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-family: 'Archivo';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          color: #212E5E;
          margin-top: 20px;
          margin-left: 20px;
          transition: all 0.8s;
          width: 100%;

          span {
            max-width: 210px;
            width: 100%;
            margin-right: auto;
            transition: all 0.5s;
          }

          svg {
            width: 64px;
            height: 52px;
            margin-right: 20px;
          }

          &:hover {
            box-shadow: 0px 5px 10px rgba(45, 83, 151, 0.15);

            span {
              color: #E75E24;
            }
          }
        }
      }
    }

    .text-img {
      position: relative;
      padding-top: 50px;

      @media (max-width: 768px) {
        padding-top: 110px;
      }

      img, svg{
        position: absolute;
        right: 0;
        left: 0;
        top: 15px;
        width: 71px;
        height: 83px;
        object-fit: cover;
        z-index: 1;
        margin: auto;

        @media (max-width: 768px) {
          top: 75px;
        }
      }

      h1 {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 46px;
        text-align: center;
        color: #212E5E;
        max-width: 740px;
        margin: auto;
        z-index: 2;
        position: absolute;
        right: 0;
        left: 0;
        z-index: 2;

        @media (max-width: 768px) {
          font-size: 28px;
          line-height: 42px;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .section-our-expertise {
    min-height: 1430px;

    .container {
      padding: 0 30px;

      .content {
        flex-direction: column-reverse;

        .title-button {

          h1,
          h2,
          p {
            display: none;
          }

          a {
            margin-top: -10px;
          }

          .elipse-icon {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section-our-expertise {
    .container {
      .content {

        .content-card {
          grid-gap: 20px;

          .card {
            padding: 17px 13px;
            font-size: 16px;
            line-height: 22px;
            flex-direction: column;
            margin: 0;
            text-align: center;

            svg {
              width: 34px;
              height: 34px;
              margin: 0;
            }
          }
        }

        .text-img {
          h1 {
            font-size: 28px;
            line-height: 42px;
          }
        }
      }
    }
  }
}
